<template>
    <div>
        <div
            v-show="show == true"
            :class="'alert-block alert alert-' + color + ' alert-dismissible'"
            role="alert"
        >
            <span class="text-bold"> {{ strong }} </span> <hr> <span v-html="text"></span>

            <div
                @click="hideAlert"
                class="close"
            >
                <span aria-hidden="true">&times;</span>
            </div>
        </div>
    </div>
</template>


<script>
import {busAlert} from "../../../main";
export default {
    name: "TheAlert",
    data() {
        return {
            show: false,
            text: '',
            strong: '',
            color: ''
        }
    },
    methods: {
        hideAlert() {
            this.show = false
        },
        showAlert() {
            this.show = true
        },
        jsShow(text, color, autoHide = 0){
            this.color = color;
            this.text = text;
            this.show = true;
            if (autoHide >= 1)
            {
                let interval = autoHide * 1000;
                setTimeout(() => this.show = false, interval)
            }
        }
    },
    created() {
        this.$root.$on('alertMessage', args => {
            this.text = args.text
            this.strong = args.strong
            this.color = args.color
            this.autoHide = args.autoHide
            if (this.text != '' || this.strong != '') {
                this.showAlert()
                if (this.autoHide >= 1) {
                    let interval = this.autoHide * 1000;
                    setTimeout(() => this.show = false, interval)
                }
            }
        });
        busAlert.$on('alertMessage', args => {
            this.text = args.text
            this.strong = args.strong
            this.color = args.color
            this.autoHide = args.autoHide
            if (this.text != '' || this.strong != '') {
                this.showAlert()
                if (this.autoHide >= 1) {
                    let interval = this.autoHide * 1000;
                    setTimeout(() => this.show = false, interval)
                }
            }
        })
    },
}
</script>

<style lang="scss" scoped>
.alert-block {
    position: absolute;
    width: 94%;
    left: 3%;
    z-index: 10;
}
</style>