<template>
    <div>
        <div
            @click="hideMenu"
            class="hamburger-nav"
        >

            <the-link
                className="nav-hamburger-li nav-hamburger-li-first"
                location="/"
                icon="white/home"
                iconWidth="20em"
                text="Domů"
            />

            <div v-for="(row, index) in navData">
                <template v-if="(($store.state.sklad.id_skladu != 12 && $store.state.sklad.id_skladu != 13) || (row['href'] == '/vydejky' || row['href'] == '/vydejky/nova-vydejka'))">
                    <the-link
                        className="nav-hamburger-li"
                        :location="row['href']"
                        :icon="'white/' + row['icon']"
                        iconWidth="20em"
                        :text="row['text']"
                    />
                </template>
            </div>

        </div>
        <div
            class="nav-footer"
            @click="hideMenu"
        >
            <the-link
                className="nav-hamburger-li nav-hamburger-li-user"
                location="/"
                icon="white/user"
                iconWidth="20em"
                :text="$store.state.uzivatel.prijmeni + ' ' + $store.state.uzivatel.jmeno"
            />
            <the-link
                className="nav-hamburger-li"
                location="/logout"
                icon="white/logout"
                iconWidth="20em"
                text="Odhlásit se"
            />
        </div>
    </div>
</template>

<script>
import TheIcon from "@/components/TheIcon";
import TheLink from "@/components/TheLink";

export default {
    components: {TheIcon, TheLink},
    data() {
        return {
            userName: '',
            navData:
                {

                    0: {
                        icon: 'detail_zbozi',
                        text: 'Detail',
                        href: '/detail'
                    },
                    /*
                    1: {
                        icon: 'parkovani_zbozi',
                        text: 'Parkování',
                        href: '/parkovani'
                    },
                    2: {
                        icon: 'zasoby',
                        text: 'Zásoby',
                        href: '/zasoby'
                    },
                    3: {
                        icon: 'prijemka',
                        text: 'Příjemky',
                        href: '/prijemky'
                    },

                    4: {
                        icon: 'prijemka',
                        text: 'Přehled příjmů',
                        href: '/prijemky/prehled'
                    },

                     */
                    5: {
                        icon: 'vydejka',
                        text: 'Výdejky',
                        href: '/vydejky'
                    },
                    6: {
                        icon: 'vydejka',
                        text: 'Nová výdejka',
                        href: '/vydejky/nova-vydejka'
                    },
                    /*
                    10: {
                        icon: 'vydejka',
                        text: 'Přehled výdejů',
                        href: '/vydejky/prehled'
                    },

                     */
                    15: {
                        icon: 'vydejka',
                        text: 'Požadavky výdej',
                        href: '/vydejky/pozadavky-vydej/prehled',
                    },
                    17: {
                      icon: 'vydejka',
                      text: 'Výdej - Eshop',
                      href: '/eshop/prehled',
                      dokoncena_agenda: '1'
                    },
                    20: {
                        icon: 'inventury',
                        text: 'Inventury',
                        href: '/inventury'
                    },
                    30: {
                        icon: 'vydejka',
                        text: 'Nepodepsané výdejky',
                        href: '/nepodepsane-vydejky'
                    },
                    50: {
                        icon: 'parkovani_zbozi',
                        text: 'Parkování',
                        href: '/parkovani-polozek',
                    },
                    60: {
                        icon: 'rezervace',
                        text: 'OP - Rezervace',
                        href: '/op/prehled-op',
                    },
                }
        }
    },
    methods: {
        hideMenu() {
            this.$root.$emit('hideMenuBus', true)
        }
    },
    created() {
        this.userName = localStorage.getItem("userName")
    }
}


</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";

.hamburger-nav {
    position: absolute;
    background: $theme-black;
    height: 75%;
    width: 100%;
    top: 5%;
    overflow: auto;
}


.hamburger-nav::-webkit-scrollbar {
    background: $theme-black;
    color: $theme-black;
    width: 1px;
}

.hamburger-nav::-webkit-scrollbar-track {
    background: $theme-black;
    width: 1px;
}

.nav-hamburger-li {
    display: list-item;
    list-style: none;
    padding-top: 1.5em;
    padding-bottom: 0em;
    padding-left: 1em;
    font-family: Arial, Helvetica, Verdana, sans-serif;
}

.nav-hamburger-li-user {
    font-size: 0.8em;
}

.nav-hamburger-li-first {
    padding-top: 0em;
}

a {
    color: $theme-white;
    font-weight: bold;
}

a:hover {
    text-decoration: none;
    color: $color-dark;
}

.nav-footer {
    position: absolute;
    width: 100%;
    border-top: 1px solid $theme-red;
    height: 19%;
    top: 81%;
    .nav-hamburger-li {
        padding-top: 10px;
    }
}

.hamburger-nav, .nav-footer {
    a:hover {
        color: $color-black;
    }
}

</style>
