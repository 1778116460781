const state = () => ({
    auth: false,
    beforeLoginPage: ''
})

const getters = {}

const actions = {}

const mutations = {
    setAuth(state, value)
    {
        state.auth = value;
    },
    setBeforeLoginPage(page)
    {
        state.beforeLoginPage = page;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}