<template>
  <div
      class="homepage-tile-wrap "
      v-bind:class="{'strikethrough': dokoncenaAgenda == 0}"
      @click="routePage"
  >
    <div class="homepage-tile-icon">
      <the-icon
          :icon="icon"
          width="60em"
          padding="10px"
      />
    </div>


    <div class="homepage-tile-text-wrap">
        <div class="homepage-tile-text">
            {{ text }}
        </div>
    </div>


    <div
        v-show="counter != 0"
        class="counter-circle"
        :class="'counter-circle-' + counterColor"
    >
      <div
          class="counter-circle-text text-center"
          :class="counterSmallName"
      >
        {{ counter }}
      </div>
    </div>

  </div>
</template>

<script>
import router from "@/router";
import TheIcon from "@/components/TheIcon";

export default {
    name: "HomepageTile",
    components: {TheIcon},
    props: {
        icon: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        href: {
            type: String,
            default: ''
        },
        index: {
            type: String,
            default: '0'
        },
        counter: {
            type: Number,
            default: 0
        },
        counterColor: {
            type: String,
            default: 'danger'
        },
        dokoncenaAgenda: {
            type: String,
            default: '0'
        }
    },
    data() {
        return {
            className: '',
            counterSmallName: ''
        }
    },
    created() {
        this.className = this.isOdd(this.index)

        if (this.counter >= 100)
        {
            this.counterSmallName = 'counter-circle-text-three'
        }
        if (this.counter < 10)
        {
            this.counterSmallName = 'counter-circle-text-one'
        }
    },
    methods: {
        routePage() {
            if (this.dokoncenaAgenda == 1)
            {
                this.$root.$emit('route', {page: this.href});
            }
        },
        isOdd(num) {
            num = parseInt(num)
            let typ = 'even'
            let modulo = num % 2
            if (modulo == 0) {
                typ = 'odd'
            }
            return typ;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/colors.scss";

$color-shadow: #ccc;

.homepage-tile-wrap {
    width: 25%;
    float: left;
    margin: 4%;
    margin-top: 1.1%;
    box-shadow: 2px 2px 8px $color-shadow;
    -moz-box-shadow: 2px 2px 8px $color-shadow;
    -webkit-box-shadow: 2px 2px 8px $color-shadow;
    -khtml-box-shadow: 2px 2px 8px $color-shadow;
    border-radius: 10%;
}

@media only screen and (min-width: 1200px) {
  .homepage-tile-wrap {
    width: 6%;
    margin: 1%;
  }
}

@media only screen and (min-width: 600px) {
    .homepage-tile-wrap {
        width: 20%;
        margin: 2.4%;
    }
}

@media only screen and (min-width: 1366px) {
    .homepage-tile-wrap {
        width: 6%;
        margin: 1%;
    }
}

.homepage-tile-icon {
    float: left;
    width: 100%;
    text-align: center;
    background: $theme-white;
}

.homepage-tile-text-wrap {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: -0.3em;
    font-size: 0.75em;
    font-family: 'Roboto', "Arial Regular", sans-serif;
    font-weight: 400;
    font-weight: bold;
    //border-top: 1px solid $theme-red;
    background: $color-light;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
}

.homepage-tile-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3em;
    margin-top: 0em;
    margin-bottom: 0.01em;
}


.homepage-tile-wrap:hover {
  .homepage-tile-text {
    color: $theme-red;
  }

  cursor: pointer;
  transform: scale(1);
  animation: pulse 3s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.95);
    }
}

.counter-circle {
  position: relative;
  z-index: 8;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
    top: -0.4em;
  //top: 4.6em;
  //left: 5.35em;
    left: 89%;
    box-shadow: 0px 2px 8px $color-shadow;
    -moz-box-shadow: 0px 2px 8px $color-shadow;
    -webkit-box-shadow: 2px 2px 8px $color-shadow;
    -khtml-box-shadow: 2px 2px 8px $color-shadow;
}

.counter-circle-text {
    position: absolute;
    top: 0%;
    left: 0.15em;
    font-size: 0.8em;
}

.counter-circle-text-one {
    left: 0.5em;
}

.counter-circle-text-three {
    font-size: 0.6em;
    top: 0.25em;
}

.counter-circle-success {
  background: $color-green;
  color: $theme-white;
}

.counter-circle-danger {
  background: $theme-red;
  color: $theme-white;
}

.strikethrough {
    position: relative;

}
.strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: red;

    -webkit-transform:rotate(-50deg);
    -moz-transform:rotate(-50deg);
    -ms-transform:rotate(-50deg);
    -o-transform:rotate(-50deg);
    transform:rotate(-50deg);
}


</style>