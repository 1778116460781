const state = () => ({
    id_skladu: 16,
    nazev_skladu: 'Sklad Hala F (5)',
    zkratka_skladu: 'F'
})

const getters = {}

const actions = {}

const mutations = {
    vyberSklad(state, skladData)
    {
        state.id_skladu = skladData.id_skladu;
        state.nazev_skladu = skladData.nazev_skladu;
        state.zkratka_skladu = skladData.zkratka_skladu;
        let json = JSON.stringify(state);

        let ls = window.localStorage;
        ls.setItem("aktvniSklad", json);
    },
    vyberSkladSession(state)
    {
        let ls = window.localStorage;
        let json = ls.getItem("aktvniSklad");
        let array = JSON.parse(json);

        if (array == null)
        {
        }
        else
        {
            state.nazev_skladu = array['nazev_skladu'];
            state.zkratka_skladu = array['zkratka_skladu'];
            state.id_skladu = array['id_skladu'];
        }


    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}