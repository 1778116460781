<template>
    <div id="confirm-modal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p> {{ text }} </p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary"> {{ textTrue }} </button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"> {{ textFalse }} </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConfirmModal",
    data() {
        return {
            showModal: false,
            text: 'Jste si jistí?',
            textTrue: 'Ano',
            textFalse: 'Ne',
            title: 'Potvrzení'
        }
    },
    created() {
        this.$root.$on('confirmModal', args => {
            if (args.text !== undefined && args.text != '')
            {
                this.text = args.text;
            }
            $('#confirm-modal').modal('show');
            return '???';
        });
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>

</style>