<template>
    <div>
        <the-router/>
        <div id="app">

            <template v-if="$store.state.layout.auth == true">
                <div class="container">
                    <the-header/>
                    <the-alert
                    />
                    <div class="container-content">
                        <router-view/>
                        <keep-alive>

                        </keep-alive>
                    </div>
                    <footer-sklad/>
                    <confirm-modal></confirm-modal>
                </div>
                <the-input
                    type="text"
                    name="barcode-scan"
                    id="barcode-scan"
                    className="barcode-scan"
                />
            </template>
            <template v-else>
                <div class="container">
                    <div class="container-content">
                        <keep-alive>
                            <router-view/>
                        </keep-alive>
                    </div>
                </div>
            </template>


        </div>
        <the-loader/>
    </div>
</template>

<script>
import TheHeader from "@/views/layouts/TheHeader";
import TheFooter from "@/views/layouts/TheFooter";
import FooterSklad from "@/views/layouts/FooterSklad";
import TheAlert from "@/views/content/others/TheAlert";
import TheInput from "@/components/form/TheInput";
import store from "@/store";
import ConfirmModal from "@/components/ConfirmModal";
import TheLoader from "@/views/content/others/TheLoader";
import TheRouter from "@/components/TheRouter";
import Helper from "@/helper";
export default {
    components: {
        TheRouter,
        ConfirmModal,
        TheFooter,
        TheHeader,
        TheAlert,
        FooterSklad,
        TheInput,
        TheLoader,
    },
    data() {
        return {

        }
    },
    created: function () {

    },
    methods: {
    }
}
</script>

<style lang="scss">
@import './assets/scss/app.scss';
</style>
