<template>
    <router-link :to="location">
        <button
            :class="'btn btn-' + color + ' ' + sizeButton + ' ' + isCircle"
            @click="clickButton"
        >
            <fa-icon class="fa-svg" :icon="icon"/>
            <span class="fa-text" v-show="value != ''">
                {{ value }}
            </span>

        </button>
    </router-link>
</template>

<script>
import FaIcon from "@/components/FaIcon";

export default {
    name: "TheButton",
    components: {FaIcon},
    props: {
        color: {
            type: String,
            default: 'success'
        },
        size: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        circle: {
            type: String,
            default: false
        },
        location: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isCircle: '',
            sizeButton: '',
        }
    },
    created() {

        if (this.circle == "true")
        {
            this.isCircle = "btn-circle"
        }

        this.sizeButton = 'btn-' + this.size
    },
    methods: {
        clickButton()
        {
            this.$emit('button', true);
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-circle {
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 0px 0px;
    font-size: 20px;
    line-height: 1.428571429;
    border-radius: 25px;

    .fa-svg {
        margin-top: 0px;
        margin-left: 0px;
    }
}

.fa-svg {
    float: left;
}

.fa-text {
    padding-left: 0.3em;
}

.btn-size-tile {
    width: 4em;
    .fa-svg {
        float: none;
    }
}


</style>