import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from "@/views/content/homepage/Homepage";

Vue.use(VueRouter)

const routes = [
    // ----------------------Content-------------------------- //
    // Homepage
    {
        path: '/',
        name: 'Skladová evidence',
        component: Homepage
    },
    // Detail
    {
        path: '/detail',
        name: 'Detail',
        component: () => import(/* webpackChunkName: "detail" */ '../views/content/detail/Detail.vue')
    },
        // Detail Pol-ND
        {
            path: '/detail/pol-nd/:id_polozky',
            name: 'Detail položky - test',
            component: () => import(/* webpackChunkName: "detail PolND" */ '../views/content/detail/PolND.vue')
        },
        {
            path: '/detail/pol-nd/:id_polozky/:id_pol_nd_polozky',
            name: 'Detail ND',
            component: () => import(/* webpackChunkName: "detail PolND" */ '../views/content/detail/PolND.vue')
        },
        {
            path: '/detail/foto/:cesta_hash',
            name: 'Detail foto',
            component: () => import(/* webpackChunkName: "detail PolND" */ '../views/content/detail/NahledFoto.vue')
        },
        {
            path: '/detail/pol-nd-v2/:id_pol_nd_polozky/:skladove_cislo/:cislo_polozky',
            name: 'Detail položky',
            component: () => import(/* webpackChunkName: "detail PolNDV2" */ '../views/content/detail/DetailPolozky.vue')
        },


    // Parkovani
    {
        path: '/parkovani',
        name: 'Parkování',
        component: () => import(/* webpackChunkName: "parkovani" */ '../views/content/parkovani/Parkovani.vue')
    },
    {
        path: '/parkovani/nepolozkovatelna-polozka/:id_parkovaci_pozice/:id_polozky',
        name: 'Parkování - Počet MJ',
        component: () => import(/* webpackChunkName: "parkovaniNepolozkovatelna" */ '../views/content/parkovani/ParkovaniNepolozkovatelne.vue')
    },
    {
        path: '/parkovani-polozek',
        name: 'Parkování položek',
        component: () => import(/* webpackChunkName: "parkovaniPolozek" */ '../views/content/parkovani/ParkovaniPolozek.vue')
    },

    // Příjemky
    {
        path: '/prijemky',
        name: 'Příjemky',
        component: () => import(/* webpackChunkName: "prijemky" */ '../views/content/prijemky/PrijemkyParkovani.vue')
    },
    {
        path: '/prijemky/parkovani/detail/:id_prijemky',
        name: 'Příjemka parkování',
        component: () => import(/* webpackChunkName: "prijemka-parkovani" */ '../views/content/prijemky/PrijemkyParkovaniDetail.vue')
    },

    // Výdejky
    {
        path: '/vydejky',
        name: 'Výdejky',
        component: () => import(/* webpackChunkName: "vydejky" */ '../views/content/vydejky/Vydejky.vue')
    },
        {
            path: '/vydejky/nova-vydejka',
            name: 'Nová výdejka',
            component: () => import(/* webpackChunkName: "vydejky" */ '../views/content/vydejky/NovaVydejka.vue')
        },
        {
            path: '/vydejky/editace/:id_vydejky/:cislo_polozky',
            name: 'Editace výdejky',
            component: () => import(/* webpackChunkName: "editaceVydejky" */ '../views/content/vydejky/EditaceVydejky.vue')
        },

    {
        path: '/vydejky/pozadavky-vydej/prehled',
        name: 'Požadavky výdej',
        component: () => import(/* webpackChunkName: "vydejky" */ '../views/content/vydejky/pozadavky_vydej/PozadavkyVydejPrehled.vue')
    },
        {
            path: '/vydejky/pozadavky-vydej/detail/:id_pozadavku',
            name: 'Detail požadavku',
            component: () => import(/* webpackChunkName: "vydejky" */ '../views/content/vydejky/pozadavky_vydej/PozadavkyVydejDetail.vue')
        },
    {
        path: '/eshop/prehled',
        name: 'Výdej - Eshop',
        component: () => import(/* webpackChunkName: "eshopPrehled" */ '../views/content/eshop/eshopPrehled.vue')
    },
        {
            path: '/eshop/detail/:id_pozadavku',
            name: 'Detail požadavku Eshop',
            component: () => import(/* webpackChunkName: "eshopDetail" */ '../views/content/eshop/eshopDetail.vue')
        },
    {
        path: '/nepodepsane-vydejky',
        name: 'Nepodepsané výdejky',
        component: () => import(/* webpackChunkName: "nepodepsane-vydejky" */ '../views/content/vydejky/NepodepsaneVydejky.vue')
    },
        {
            path: '/nepodepsane-vydejky/podepsat/:id_vydejky',
            name: 'Podepsat výdejku',
            component: () => import(/* webpackChunkName: "podepsat-vydejku" */ '../views/content/vydejky/PodepsatVydejku.vue')
        },
        {
            path: '/vydejky/tisk/:id_vydejky',
            name: 'Vytisknout výdejku',
            component: () => import(/* webpackChunkName: "vytisknout-vydejku" */ '../views/content/vydejky/VytisknoutVydejku.vue')
        },
    // Inventury
    {
        path: '/inventury',
        name: 'Inventury',
        component: () => import(/* webpackChunkName: "inventury" */ '../views/content/inventury/Inventury.vue')
    },
    {
        path: '/inventury/detail/:id_inventury',
        name: 'Inventura - regály',
        component: () => import(/* webpackChunkName: "inventuryDetail" */ '../views/content/inventury/InventuryDetail.vue')
    },
    {
        path: '/inventury/detail/:id_inventury/regal/:id_regalu',
        name: 'Inventura - detail regálu',
        component: () => import(/* webpackChunkName: "inventuryDetailRegalu" */ '../views/content/inventury/InventuryDetailRegalu.vue')
    },
    {
        path: '/inventury/detail/:id_inventury/regal/:id_regalu/parkovaci-pozice/:id_pozice_regalu',
        name: 'Inventura - par. pozice',
        component: () => import(/* webpackChunkName: "inventuryDetailParkovaciPozice" */ '../views/content/inventury/InventuryDetailParkovaciPozice.vue')
    },
    {
        path: '/inventury/detail/:id_inventury/regal/:id_regalu/parkovaci-pozice/:id_pozice_regalu/polozka/:id_inventury_polozky',
        name: 'Detail položky (I)',
        component: () => import(/* webpackChunkName: "InventuryDetailPolozky" */ '../views/content/inventury/InventuryDetailPolozky.vue')
    },


    // Parkování
    // Regály - detail
    {
        path: '/regaly/:id_regalu',
        name: 'Přehled regálu',
        component: () => import(/* webpackChunkName: "Prehled regalu" */ '../views/content/parkovani/DetailRegalu.vue')
    },

    // Nastavení
    {
        path: '/nastaveni',
        name: 'Nastavení',
        component: () => import(/* webpackChunkName: "nastaveni" */ '../views/content/nastaveni/Nastaveni.vue')
    },
    {
        path: '/nastaveni/vyber-skladu',
        name: 'Výběr skladu',
        component: () => import(/* webpackChunkName: "nastaveni-vyber-skladu" */ '../views/content/nastaveni/VyberSkladu.vue')
    },

    // Components
    {
        path: '/component/table',
        name: 'Component | Table',
        component: () => import(/* webpackChunkName: "Table" */ '../views/component/ComponentTable.vue')
    },

    // Rezervace OP
    {
        path: '/op/prehled-op',
        name: 'OP - Rezervace',
        component: () => import(/* webpackChunkName: "op-prehled-op" */ '../views/content/op/PrehledOP.vue')
    },
    {
        path: '/op/prehled-op/detail/:id_pozadavku_polozky',
        name: 'OP - Detail rezervace',
        component: () => import(/* webpackChunkName: "op-prehled-detail" */ '../views/content/op/DetailOP.vue')
    },

    // Detaily
    {
        path: '/detail/regal/:id_regalu',
        name: 'Detail regálu',
        component: () => import(/* webpackChunkName: "Detail regalu" */ '../views/content/detail/Regal.vue')
    },
    {
        path: '/detail/parkovaci-pozice/:id_pozice_regalu',
        name: 'Detail parkovací pozice',
        component: () => import(/* webpackChunkName: "Detail parkovací pozice" */ '../views/content/detail/ParkovaciPozice.vue')
    },

    // Auth
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/content/others/TheLogin.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "Logout" */ '../views/content/others/TheLogout.vue')
    },
    // 404
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/content/others/NotFound.vue')
    },
    // Test
    {
        path: '/test',
        name: 'test',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/content/others/TheTest.vue')
    },
    {
        path: '*',
        redirect: '/404'
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
