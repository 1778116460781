import axios from "axios";

export default {
    methods: {
        axiosApiPost(url, params)
        {
            const paramsEdit = new URLSearchParams();
            $.each(params, function (key, value) {
                paramsEdit.append(key, value);
            });

            let configAxios = {
                headers: {
                    "Accept":"application/json",
                    "Content-type":"application/x-www-form-urlencoded",
                }
            }

            return new Promise((resolve, reject) => {
                axios.post(apiUrl + url, paramsEdit, configAxios).then(function (response) {
                    resolve(response);
                }).catch(function (error)
                {
                    reject(error);
                });
            });
        }
    }
}