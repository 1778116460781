const state = () => ({
    id_pol_nd_polozky: 0,
    id_parkovaci_pozice: 0,
    id_polozky: 0,
})

const getters = {}

const actions = {}

const mutations = {
    nactiBarcode(state, barcode)
    {
        state.id_pol_nd_polozky = barcode.id_pol_nd_polozky;
        state.id_parkovaci_pozice = barcode.id_parkovaci_pozice;
        state.id_polozky = barcode.id_polozky;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}