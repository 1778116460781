import axios from "axios";
import store from '../store/index.js';
import TheLoader from "@/views/content/others/TheLoader";
import {getIDEshopu} from "@/local_config";
//TheLoader.methods.setShowLoader('true');

export default function axiosApiPostV2(url, params, responseKey = '')
{
    if (store.state.loading.showLoadingScreen == false)
    {

    }
    const paramsEdit = new URLSearchParams();
    $.each(params, function (key, value) {
        paramsEdit.append(key, value);
    });
    paramsEdit.append('api_id_uzivatele', store.state.uzivatel.id_uzivatele);
    paramsEdit.append('id_skladu_session', store.state.sklad.id_skladu);
    paramsEdit.append('id_eshopu', getIDEshopu());
    let configAxios = {
        headers: {
            "Accept":"application/json",
            "Content-type":"application/x-www-form-urlencoded",
        }
    }

    return new Promise((resolve, reject) => {
        //console.log(apiUrl + url);
        axios.post(apiUrl + url, paramsEdit, configAxios).then(function (response) {
            if (response.data.status == 'ok')
            {
                if (responseKey != '')
                {
                    resolve(response.data[responseKey]);
                }
                else
                {
                    resolve(response);
                }
            }
        }).catch(function (error)
        {
            //console.log(error);
            reject(error);
        });
    });
}