<template>
    <div class="loader-wrap" v-if="showLoader == true">

        <div class="loader">

        </div>
            <div class="row wrap-spinners">
                <div class="main-spinner">
                    <div class="spinner-border" role="status"></div>
                </div>
                <!--
                <div class="grow-spinner">
                    <div class="spinner-grow text-primary" role="status"></div>
                    <div class="spinner-grow text-success" role="status"></div>
                    <div class="spinner-grow text-info" role="status"></div>
                    <div class="spinner-grow text-danger" role="status"></div>
                    <div class="spinner-grow text-warning" role="status"></div>
                    <div class="spinner-grow text-light" role="status"></div>
                    <div class="spinner-grow text-dark" role="status"></div>
                </div>
                -->
            </div>
    </div>
</template>

<script>
export default {
    name: "TheLoader",
    data() {
        return {
            showLoader: false,
            autoHide: 0
        }
    },
    created() {
        this.$root.$on('showLoader', args => {
            if (args.show == true || args.show == false)
            {
                this.$store.commit('loading/setShowLoadingScreen', args.show);
                this.showLoader = args.show
            }
            if (args.autoHide >= 1)
            {
                this.autoHide = args.autoHide;
            }
            if (this.autoHide >= 1)
            {
                let interval = this.autoHide * 1000;
                setTimeout(() => this.showLoader = false, interval)
            }
        });
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
    img {
        position: absolute;
        z-index: 9999;
        top: 0;
        left: 0;
        //height: 100%;
    }

    .main-spinner {
        top: 25%;
        position: absolute;
        .spinner-border {
            height: 250px;
            width: 250px;
            border-width: 1em;
        }
    }
</style>