<template>
    <div>

    </div>
</template>

<script>
import router from "@/router";

export default {
    name: "TheRouter",
    data() {
        return {

        }
    },
    created() {
        this.$root.$on('route', args => {
            if (args.delay != undefined && args.delay > 0)
            {
                setTimeout(function(){
                    router.push(args.page);
                }, args.delay * 1000);
            }
            else
            {
                router.push(args.page);
            }

        });
    }
}
</script>

<style lang="scss" scoped>

</style>