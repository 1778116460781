<template>
    <div>

      <div v-for="(tileData, index) in tilesData">
          <template v-if="(($store.state.sklad.id_skladu != 12 && $store.state.sklad.id_skladu != 13) || (tileData['href'] == '/vydejky' || tileData['href'] == '/vydejky/nova-vydejka'))">
              <homepage-tile
                  :text="tileData['text']"
                  :icon="tileData['icon']"
                  :href="tileData['href']"
                  :index="index"
                  :counter="tileData['counter']"
                  :counterColor="tileData['counterColor']"
                  :dokoncenaAgenda="tileData['dokoncena_agenda']"
              />
          </template>
      </div>
    </div>
</template>

<script>
import HomepageTile from "@/components/homepage/HomepageTile";
import axiosApiPostV2 from "@/router/axiosApiMethod";

export default {
    name: "Homepage",
    components: {HomepageTile, axiosApiPostV2},
    data() {
        return {
            counterArray: {},
            tilesData: {},
            appLocation: window.appLocation
        }
    },
    created() {
        this.$root.$emit('showLoader', {show: true});
        axiosApiPostV2('uvodni-strana', {}, 'data').then(result => {

            let counter = result;
            this.tilesData = {

                0: {
                    icon: 'detail_zbozi',
                    text: 'Detail náhradního dílu',
                    href: '/detail',
                    dokoncena_agenda: '1'
                },
                /*
                1: {
                    icon: 'parkovani_zbozi',
                    text: 'Parkování',
                    href: '/parkovani'
                },
                3: {
                    icon: 'zasoby',
                    text: 'Zásoby',
                    href: '',
                    counter: 0
                },
                },

                4: {
                    icon: 'prijemka',
                    text: 'Příjemky',
                    href: '/prijemky',
                    dokoncena_agenda: '1'
                },
                5: {
                    icon: 'prijemka',
                    text: 'Přehled příjmů',
                    href: '/prijemky',
                    dokoncena_agenda: '1'
                },
                 */
                6: {
                    icon: 'vydejka',
                    text: 'Výdejky',
                    href: '/vydejky',
                    dokoncena_agenda: '1'
                },
                7: {
                    icon: 'vydejka',
                    text: 'Nová výdejka',
                    href: '/vydejky/nova-vydejka',
                    dokoncena_agenda: '1'
                },
                /*
                10: {
                    icon: 'vydejka',
                    text: 'Přehled výdejů',
                    href: '/vydejky/prehled-vydeju',
                    dokoncena_agenda: '1'
                },
                 */
                15: {
                    icon: 'vydejka',
                    text: 'Požadavky výdej',
                    href: '/vydejky/pozadavky-vydej/prehled',
                    dokoncena_agenda: '1'
                },
                17: {
                  icon: 'vydejka',
                  text: 'Výdej - Eshop',
                  href: '/eshop/prehled',
                  dokoncena_agenda: '1'
                },
                20: {
                    icon: 'inventury',
                    text: 'Inventury',
                    href: '/inventury',
                    counter: counter.inventury,
                    dokoncena_agenda: '1'
                },
                /*
                9: {
                    icon: 'prevody_zadane_obchodnikem',
                    text: 'Převody zadané obchodníkem',
                    href: '',
                    counter: 2
                },
                10: {
                    icon: 'rezervace',
                    text: 'Rezervace',
                    href: '',
                    counter: 999
                },
                11: {
                    icon: 'prodane_nevyskladnene_zbozi',
                    text: 'Prodané zboží',
                    href: '',
                    counter: 47
                },
                 */
                30: {
                    icon: 'nepodepsane_vydejky',
                    text: 'Nepodepsané výdejky',
                    href: '/nepodepsane-vydejky',
                    counter: counter.nepodepsane_vydejky,
                    dokoncena_agenda: '1'
                },
                /*
                13: {
                    icon: 'nedokoncene_prevodky',
                    text: 'Nedokončené převodky',
                    href: '',
                    counter: 21
                },
*/
                100: {
                    icon: 'parkovani_zbozi',
                    text: 'Parkování',
                    href: '/parkovani-polozek',
                    dokoncena_agenda: '1'
                },

                120: {
                    icon: 'rezervace',
                    text: 'OP - Rezervace',
                    href: '/op/prehled-op',
                    dokoncena_agenda: '1'
                },



            };
            this.$root.$emit('showLoader', {show: false});
        });
    }
}
</script>

<style lang="scss" scoped>

</style>