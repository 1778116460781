import store from "@/store";

const state = () => ({
    jmeno: '',
    prijmeni: '',
    id_uzivatele: 0,
    jwt: ''
})

const getters = {}

const actions = {
}

const mutations = {
    loadUserData(state, userData)
    {
        state.jmeno = userData.jmeno;
        state.prijmeni = userData.prijmeni;
        state.id_uzivatele = userData.id_uzivatele;
        state.jwt = userData.jwt;
    },
    loadUserDataFromLocalStorage(state)
    {
        if (localStorage.getItem("prihlasenyUzivatel") !== null)
        {
            let userData = window.localStorage.getItem('prihlasenyUzivatel');
            userData = JSON.parse(userData);
            state.jmeno = userData.jmeno;
            state.prijmeni = userData.prijmeni;
            state.id_uzivatele = userData.id_uzivatele;
            state.jwt = userData.jwt;
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}