import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import Helper from './helper'
import Vue2TouchEvents from 'vue2-touch-events'
import { CoolSelectPlugin } from "vue-cool-select";
import("vue-cool-select/dist/themes/bootstrap.css");
Vue.prototype.$nactenyNahraniDil = {
    id_polozky: 0,
    skladove_cislo: 0,
    cislo_polozky: 0,
    id_uzivatele: 0
};


// Config pro IP - adresa aplikace, api, atd...
import { localConfig } from "@/local_config";

import titleMixin from './mixins/titleMixin'
Vue.mixin(titleMixin)

window.axios = axios

window.Helper = Helper;

window.barCode = {};

// Definujeme si základní proměnné pro aplikaci
let localConfigArray = localConfig()
window.apiUrl = localConfigArray.api_url

console.log(window.apiUrl);

window.appUrl = localConfigArray.app_url
window.appCleanUrl = localConfigArray.app_url.replace(':8080', '');
window.baseUrl = localConfigArray.base_url
window.appDir = appUrl.replace(':8080', '/ctecka-sklady/src/assets/')
window.appPage = baseUrl.replace(appUrl, '');
let appLocation = appUrl;
if (appLocation.indexOf('.vzv.cz') > 0)
{
    appLocation = 'https://sklady.vzv.cz';
}
window.appLocation = appLocation;


if (appUrl.indexOf('.vzv.cz') > 0)
{
    window.adminDir = 'http://admin.vzv.cz/';
}
else
{
    window.adminDir = appUrl.replace(':8080', '/admin/');
}



let isMobile = false;
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    isMobile = true;
}
if (isMobile === false)
{
    $('head').append('<link rel="stylesheet" type="text/css" href="'+ appDir +'/scss/style-pc-devices.css">');
}

Vue.prototype.$beepSoundNameFile = 'beep';
import { routerBarcode } from './router/barcode';
import { routerDetail } from './router/routerDetail';
$('body').keydown(function(event)
{
    if ((event.originalEvent.key == 'Unidentified' && event.originalEvent.code == 'F16') || (event.originalEvent.key == 'F9' && event.originalEvent.code == 'F9') || (event.originalEvent.key == 'Unidentified' && event.originalEvent.code == ''))
    {
        //$('#barcode-scan').focus()
    }
});

window.nactenyBarcode = '';
window.BARCODEENABLE = false;


$('body').keydown(function(event) {

    if((event.key == 'F9' || event.originalEvent.code == 'F16') || (event.key == 'Unidentified') && event.keyCode != 229) // || event.key == 'Unidentified'
    {
        $('#barcode-scan').val('');
        $('#barcode-scan').focus();
        window.BARCODEENABLE = true;
    }

    if(event.key == 'Enter' && event.keyCode != 229)
    {
        window.BARCODEENABLE = false;
        $('#barcode-scan').blur();
        var barcode = $('#barcode-scan').val();
        $('#barcode-scan').val('');

        window.nactenyBarcode = barcode;
        $.playSound(require('@/assets/sounds/'+ Vue.prototype.$beepSoundNameFile +'.mp3'));
        routerBarcode(barcode, window.appPage);

        /*
        if (barcode != window.nactenyBarcode && barcode != '')
        {
            window.nactenyBarcode = barcode;
            $.playSound(require('@/assets/sounds/'+ Vue.prototype.$beepSoundNameFile +'.mp3'));
            routerBarcode(barcode, window.appPage);
        }
         */
    }
});


/*
$('body').keydown(function(event) {
    if(event.key == 'F9' || event.originalEvent.code == 'F16' || event.originalEvent.key == 'Enter') // || event.key == 'Unidentified'
    {
        $('#barcode-scan').val('');
        $('#barcode-scan').focus();
        window.BARCODEENABLE = true;
    }

    if((event.key == 'Enter' || event.originalEvent.key == 'Enter') && $('#barcode-scan').val() != '')
    {
        $('#barcode-scan').blur();
        var barcode = $('#barcode-scan').val();
        console.log(barcode);
        if (barcode != window.nactenyBarcode && barcode != '')
        {
            window.nactenyBarcode = barcode;
            $.playSound(require('@/assets/sounds/'+ Vue.prototype.$beepSoundNameFile +'.mp3'));
            routerBarcode(barcode, window.appPage);
        }
    }
});
*/

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faVuejs} from '@fortawesome/free-brands-svg-icons'
import '@/mixins/axiosApi'
import '@/mixins/helper'

import vSelect from "vue-select";

Vue.component("v-select", vSelect);

library.add(faVuejs)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import {fas} from '@fortawesome/free-solid-svg-icons'

library.add(fas)

Vue.config.productionTip = false

export const busBarcode = new Vue();
export const busBarcodeVydejka = new Vue();
export const busBarcodePozadavekVydejka = new Vue();
export const busBarcodeEshop = new Vue();
export const busBarcodeParkovani = new Vue();
export const busBarcodeZmenaParkovaciPoziceDetailPolozky = new Vue();
export const busBarcodeOdstraneniVydejky = new Vue();
export const busBarcodePrehledVydejek = new Vue();
export const busAlert = new Vue();
export const busReload = new Vue();
export const busBarcodeDetailParkovaciPozice = new Vue();
export const busBarcodeInventuraRegal = new Vue();
export const busBarcodeInventuraPolozky = new Vue();
export const busBarcodeOPDetail = new Vue();


Vue.use(Helper);
Vue.use(Vue2TouchEvents);
Vue.use(CoolSelectPlugin);

new Vue({
    routerBarcode,
    router,
    store,
    data: {
        showConfirmModal: false
    },
    render: h => h(App)
}).$mount('#app')

if (store.state.layout.auth == false)
{
    //let localStorageSave = store.commit('uzivatel/loadUserDataFromLocalStorage');
    //store.commit('layout/setAuth', true);
}
store.commit('sklad/vyberSkladSession');

if (window.localStorage.getItem("prihlasenyUzivatel") !== null)
{
    store.commit('uzivatel/loadUserDataFromLocalStorage');
    store.commit('layout/setAuth', true);
}

if (store.state.layout.auth == false)
{
    if (appPage != '/login' && appPage !== undefined)
    {
        if (appPage == '/logout')
        {
            appPage = '/logout';
        }

        if (appPage != '' && appPage != '/logout' && appPage != '/404')
        {
            localStorage.setItem('beforeLogin', appPage)
        }
        //this.$root.$emit('route', {page: '/login'});
        router.push('/login');
    }
}
else
{
    if (appPage == '/login')
    {
        router.push('/');
    }
}

