const state = () => ({
    showLoadingScreen: false,
})

const getters = {}

const actions = {}

const mutations = {
    setShowLoadingScreen(state, value)
    {
        state.showLoadingScreen = value;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}