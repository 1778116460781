<template>
    <div
        v-show="icon != ''"
    >
        <font-awesome-icon :icon="icon"/>
    </div>
</template>

<script>
export default {
    name: "FaIcon",
    props: {
        icon: ''
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>
