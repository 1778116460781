<template>
    <div>
        <div class="row header">
            <div class="col-1 header-chevron-left">
                <i class="fas fa-chevron-left" @click="prevPage"></i>
            </div>

            <div class="col-9 header-title">
                {{ $route.name }}
            </div>

            <div class="col-1 text-left header-sync" @click="refreshPage">
                <i class="fas fa-sync-alt"></i>
            </div>
            <div class="col-1 text-center header-bars" @click="menuShowHide">
                <font-awesome-icon icon="bars"/>
            </div>
        </div>



        <div
            v-show="menuShow == true"
        >
            <div
                class="nav-shadow"
                @click="menuShowHide"
            >

            </div>
            <div
                id="nav"
                class="nav-hamburger"

            >
                <p
                    class="text-right"

                >
                    <font-awesome-icon
                        icon="times"
                        @click="menuShowHide"
                    />
                </p>
                <the-navigation/>
            </div>
        </div>
    </div>
</template>

<script>
import TheNavigation from "@/views/layouts/TheNavigation";
import TheButton from "@/components/TheButton";

import router from "@/router";

export default {
    name: "TheHeader",
    components: {
        TheNavigation,
        TheButton
    },
    methods: {
        prevPage() {
            router.go(-1)
        },
        menuShowHide() {
            if (this.menuShow == false) {
                this.menuShow = true
            } else {
                this.menuShow = false
            }
        },
        refreshPage()
        {
            window.location.replace(document.URL);
            //router.go();
        }
    },
    data() {
        return {
            menuShow: false,
        }
    },
    created() {
        this.$root.$on('hideMenuBus', args => {
            this.menuShow = false
        })
    }

}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";

.header {
    background: $theme-black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 46px;
    max-height: 46px;
    overflow-y: hidden;
    color: $theme-white;
    font-size: 1.2rem;


    .header-chevron-left {
        margin-top: 4px;
        padding-left: 10px;
        font-size: 1.5rem;
    }

    .header-title {
        font-weight: bold;
        margin-top: 8px;
    }

    .header-sync, .header-bars {
        margin-top: 10px;
    }

}

.nav-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $theme-black;
  opacity: 0.7;
  z-index: 12;
}

.header-nazev-skladu {
  color: $color-yellow
}

.nav-hamburger {
  background: $theme-black;
  position: fixed;
  z-index: 20;
  left: 55%;
  width: 45%;
  top: 0;
  height: 100%;


  p {

    color: $theme-white;
    padding-top: 0.3em;
    padding-right: 1.1em;
    font-size: 1.4em;
  }

  p:hover {
    color: $color-black;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1200px) {
  .nav-hamburger {
    width: 15%;
    left: 85%;

    p {
      padding-right: 2em;
    }
  }
}

</style>
