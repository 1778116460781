import Vue from "vue";
import router from "@/router/index";
import axiosApi from "@/mixins/axiosApi";
import axiosApiPostV2 from "@/router/axiosApiMethod";
import { routerDetail } from './routerDetail';
import TheAlert from "@/views/content/others/TheAlert";
import {
    busBarcode,
    busBarcodeInventuraPolozky,
    busBarcodeInventuraRegal,
    busBarcodeOdstraneniVydejky,
    busBarcodePozadavekVydejka,
    busBarcodeEshop,
} from "../main";
import {busBarcodeVydejka} from "../main";
import {busBarcodeParkovani} from "../main";
import {busAlert} from "../main";
import {busReload} from "../main";
import {busBarcodeZmenaParkovaciPoziceDetailPolozky} from "../main";
import {busBarcodePrehledVydejek} from "../main";
import {busBarcodeDetailParkovaciPozice} from "../main";
import {inventuryBarcode} from "./inventury";
import {busBarcodeOPDetail} from "../main";

export function routerBarcode(barcode, url)
{
    let decodeBarcode = getDecodeBarcode(barcode);
    if (decodeBarcode['akcni_cislo'] > 0)
    {
        busBarcode.$emit('loadBarcode', {'barcode': decodeBarcode});
        runRouterBarcode(decodeBarcode);
    }

    function runRouterBarcode(barcode) {
        let url = getActualUrl();
        url = getParseActualUrl(url);
        window.barCode = barcode;

        if (url[0] == 'inventury')
        {
            inventuryBarcode(barcode, url);
            return;
        }

        if (url[0] == 'op' && url[1] == 'prehled-op' && url[2] == 'detail')
        {
            busBarcodeOPDetail.$emit('loadBarcode', {'barcode': decodeBarcode});
            return;
        }

        if (url[0] == 'vydejky' && url[1] == 'nova-vydejka')
        {
            busBarcodeVydejka.$emit('loadBarcode', {'barcode': decodeBarcode});
            if (barcode.akcni_cislo == 999 && barcode.id_uzivatele > 0)
            {
                Vue.prototype.$nactenyNahraniDil.id_uzivatele = barcode.id_uzivatele;
            }
            return;
        }

        if (url[0] == 'vydejky' && url[1] == 'pozadavky-vydej' && url[2] == 'detail')
        {
            busBarcodePozadavekVydejka.$emit('loadBarcode', {'barcode': decodeBarcode});
            return;
        }
        if (url[0] == 'parkovani-polozek' || url[0] == 'parkovani-polozekparkovani-polozek')
        {
            busBarcodeParkovani.$emit('loadBarcode', {'barcode': decodeBarcode});
            return;
        }

        if ((url[0] == 'prijemky' || url[0] == 'prijemkyprijemky') && (url[1] == 'parkovani' || url[1] == 'parkovaniparkovani') && (url[2] == 'detail' || url[2] == 'detaildetail'))
        {
            return;
        }

        if (url[0] == 'vydejky' && url[1] == 'editace')
        {
            busBarcodeOdstraneniVydejky.$emit('loadBarcode', {'barcode': decodeBarcode});
            return;
        }

        if (url[0] == 'vydejky')
        {
            busBarcodePrehledVydejek.$emit('loadBarcode', {'barcode': decodeBarcode});
            return;
        }

        if (url[0] == 'eshop' && url[1] == 'detail')
        {
            busBarcodeEshop.$emit('loadBarcode', {'barcode': decodeBarcode});
            return;
        }

        if (url[0] == 'detail' && url[1] == 'parkovaci-pozice')
        {
            busBarcodeDetailParkovaciPozice.$emit('loadBarcode', {'barcode': decodeBarcode});
            return;
        }

        if (url[0] == 'detail' && url[1] == 'pol-nd-v2')
        {
            if (barcode.akcni_cislo == 1)
            {
                busBarcodeZmenaParkovaciPoziceDetailPolozky.$emit('loadBarcode', {'barcode': decodeBarcode});
            }
            if (barcode.akcni_cislo == 2)
            {
                let cisloPolozky = 0;
                if (barcode.cislo_polozky != null  && barcode.cislo_polozky != undefined && barcode.cislo_polozky > 0)
                {
                    cisloPolozky = barcode.cislo_polozky;
                }
                barcode.cislo_polozky = cisloPolozky;
                busBarcodeZmenaParkovaciPoziceDetailPolozky.$emit('loadBarcode', {'barcode': decodeBarcode});
            }
            return;
        }


        if (barcode.akcni_cislo == 1)
        {
            routerDetail({typ: 'detail_parkovaci_pozice', id_parkovaci_pozice: barcode.id_parkovaci_pozice}, url);
            return;
        }

        if (barcode.akcni_cislo == 2)
        {
            Vue.prototype.$nactenyNahraniDil.id_pol_nd_polozky = barcode.id_pol_nd_polozky;
            Vue.prototype.$nactenyNahraniDil.cislo_polozky = barcode.cislo_polozky;

            let cisloPolozky = 0;
            if (barcode.cislo_polozky != null && barcode.cislo_polozky != undefined && barcode.cislo_polozky > 0)
            {
                cisloPolozky = barcode.cislo_polozky;
            }
            routerDetail({typ: 'detail_pol_nd', id_pol_nd_polozky: barcode.id_pol_nd_polozky, skladove_cislo: barcode.skladove_cislo, cislo_polozky: cisloPolozky}, url);
            return;
        }


    }

    function getParseActualUrl(url)
    {
        console.log('?');
        let urlArray = url.split('/');
        urlArray.shift();
        return urlArray;
    }

    function getActualUrl()
    {
        let baseUrl = window.location.href
        if (baseUrl.indexOf("localhost") > 0 || baseUrl.indexOf("192.168.") > 0)
        {
            let appUrlArray = baseUrl.split(':8080');
            return appUrlArray[1];
        }
        else
        {
            let appUrlArray = baseUrl.split('vzv.cz');
            return appUrlArray[1];
        }
        return '';
    }

    function getDecodeBarcode(barcode)
    {
        let array = barcode.split('|')
        let akcniCislo = array[0]

        if (akcniCislo.length >= 1 && akcniCislo.length <= 3)
        {
            akcniCislo = parseInt(akcniCislo)
            let id = array[1]
            if (akcniCislo == 999)
            {
                id = array[2];
            }
            id = parseInt(id)
            let skladove_cislo = ''
            let cislo_polozky = ''
            let cislo_baleni = ''

            let idKeyName = '';
            if (akcniCislo == 1)
            {
                idKeyName = 'id_parkovaci_pozice'
            }

            if (akcniCislo == 2 || akcniCislo == 3)
            {
                idKeyName = 'id_pol_nd_polozky'
                skladove_cislo = array[2]
            }

            if (akcniCislo == 2)
            {
                cislo_polozky = array[3]
            }

            if (akcniCislo == 3)
            {
                cislo_baleni = array[3]
            }

            if (akcniCislo == 4)
            {
                idKeyName = 'id_boxu'
            }

            if (akcniCislo == 5)
            {
                idKeyName = 'id_polozky'
                skladove_cislo = array[2]
            }

            if (akcniCislo == 999)
            {
                switch (array[1])
                {
                    case 'U':
                        idKeyName = 'id_uzivatele';
                        break;
                }
            }

            let barcodeArray = {
                'akcni_cislo': akcniCislo,
                [idKeyName]: id
            };
            if (skladove_cislo != '')
            {
                skladove_cislo = parseInt(skladove_cislo)
                barcodeArray['skladove_cislo'] = skladove_cislo
            }
            if (cislo_polozky != '')
            {
                cislo_polozky = parseInt(cislo_polozky)
                barcodeArray['cislo_polozky'] = cislo_polozky
            }
            if (cislo_baleni != '')
            {
                barcodeArray['cislo_baleni'] = cislo_baleni
            }
            return barcodeArray
        }
        else
        {
            let array = {
                'akcni_cislo': 0
            };
            return array;
        }


    }
}

