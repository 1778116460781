<template>
    <router-link
        :class="className + ' ' + classNameMounted"
        :to="location"
    >
    <div
        class="router-link-icon"
        v-if="icon != ''"
    >
      <the-icon
          :icon="icon"
          :width="iconWidth"
      />
    </div>
      <div class="router-link-text">
        {{ text }}
      </div>
    </router-link>
</template>

<script>
import TheIcon from "@/components/TheIcon";

export default {
    components: {TheIcon},
    name: "TheLink",
    props: {
        location: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
      iconWidth: {
          type: String,
          default: '100%'
      },
        text: {
            type: String,
            default: ''
        },
        className: {
            type: String,
            default: ''
        },
        index: {
            type: String,
            default: '99'
        },
        classNameFirstIndex: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            classNameMounted: ''
        }
    },
    methods: {
        classNameFirstIndexText(index, classNameFirstIndex) {
            if (index == 0 && classNameFirstIndex != '') {
                return classNameFirstIndex;
            }
            return '';
        }
    },
    mounted: function () {
        this.classNameMounted = this.classNameFirstIndexText(this.index, this.classNameFirstIndex)
    }
}
</script>

<style lang="scss" scoped>
  .router-link-icon {
    float: left;
  }
  .nav-hamburger {
      .router-link-text {
          margin-left: 2.2em;
          margin-top: 0.2em;
          font-size: 1em;
          //letter-spacing: 0.15em;
          //font-weight: bold;
          //font-family: 'Anton', sans-serif;
      }
  }
</style>