<template>
    <div>
        <input
            :type="type"
            :value="value"
            :placeholder="placeholder"
            :onchange="onchange"
            :onkeyup="onkeyup"
            :id="id"
            :name="name"
            :class="className"
            :readonly="readonly"
            class="input"
        />
    </div>
</template>

<script>
export default {
    name: "TheInput",
    props: {
        type: '',
        value: '',
        onchange: '',
        placeholder: '',
        onkeyup: '',
        id: '',
        name: '',
        className: '',
        readonly: false
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>