<template>
    <div>
        <img
            :src="appDir + 'img/icon/' + icon + '.' + extension"
            :width="width"
            :style="'padding: ' + padding + '; color: '+ color"
        >
    </div>
</template>

<script>
export default {
    name: "TheIcon",
    props: {
        icon: {
            type: String,
            default: ''
        },
        extension: {
            type: String,
            default: 'svg'
        },
        width: {
            type: String,
            default: '100%'
        },
        padding: {
            type: String,
            default: '0px'
        },
        color: {
            type: String,
            default: 'black'
        }
    },
    data() {
        return {
            appDir
        }
    }
}
</script>

<style lang="scss" scoped>

</style>