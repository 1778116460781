<template>
  <div>
    <div class="row footer-sklad">
      <div class="col-6 text-center text-bold footer-sklad-sklad">
        <the-link
            :text="$store.state.sklad.nazev_skladu"
            location="/nastaveni/vyber-skladu"
        />
      </div>
      <div class="col-6 text-center footer-sklad-uzivatel">
        {{ $store.state.uzivatel.prijmeni }} {{ $store.state.uzivatel.jmeno }}
      </div>
    </div>
  </div>
</template>

<script>
import TheLink from "@/components/TheLink";
export default {
  name: "FooterSklad",
    components: {
      TheLink
    },
    data() {
        return {
        }
    },
    methods: {
    },
   mounted() {
   },

}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";
    .footer-sklad {
      background: $theme-black;
      color: $theme-white;
      height: 5%;
      padding-top: 0.5em;
      font-size: 0.9em;
      position: fixed;
      top: 95%;
      left: 0;
      margin-left: 0em;
      margin-right: 0em;
      width: 100%;
      z-index: 12;
    }
.footer-sklad-sklad
{
    margin-top: 0em;
    a {
        color: $theme-white;
        font-size: 0.9em;
    }
    a:hover {
        color: $color-black;
    }
}

@media only screen and (min-width: 600px) {
    .footer-sklad {
        top: 92%;
        height: 8%;
    }
}

.footer-sklad-uzivatel {

}

</style>