import Vue from 'vue'
import Vuex from 'vuex'
import uzivatel from "@/store/uzivatel/uzivatel";
import layout from "@/store/layout/layout";
import sklad from "@/store/sklad/sklad";
import loading from "@/store/layout/loading";
import barcode from "@/store/barcode/barcode";
Vue.use(Vuex)


export default new Vuex.Store({
    modules: {
        uzivatel,
        layout,
        sklad,
        loading,
        barcode
    }
})


