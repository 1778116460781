<template>
    <b-row class="footer">
        <b-col
            cols="3"
            class="text-center"
            @click="goHome"
        >
            <the-button
                icon="home"
                circle="true"
                color="light"
                size=""
                class="footer-button"
            />
        </b-col>

        <b-col
            cols="3"
            class="text-center"
        >
            <the-button
                icon="chevron-left"
                circle="true"
                color="light"
                size=""
                class="footer-button"
            />
        </b-col>

        <b-col
            cols="3"
            class="text-center"
        >
            <the-button
                icon="chevron-right"
                circle="true"
                color="light"
                size=""
                class="footer-button"
            />
        </b-col>

        <b-col
            cols="3"
            class="text-center"
        >
            <the-button
                icon="plus"
                circle="true"
                color="light"
                size=""
                class="footer-button"
            />
        </b-col>
    </b-row>
</template>

<script>
import TheButton from "@/components/TheButton";

export default {
    components: {
        TheButton
    },
    name: "TheFooter",
    data() {
        return {}
    },
    methods: {
        goHome() {
            this.$root.$emit('route', {page: '/'});
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";

.footer {
    width: 100%;
    height: 63px;
    background: $theme-black;
    position: fixed;
    top: 90%;
    left: 0;
    margin-left: 0px;
    margin-right: 0px;

    .footer-button {
        margin-top: 12px;
    }
}
</style>
